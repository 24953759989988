import _ from 'lodash';
import * as fb from '@/firebase';
import Schema from 'async-validator';
/* eslint-disable class-methods-use-this */
export default class BaseModule {
  constructor(options) {
    this.name = options.name;
    this.path = options.path;
    this.schema = options.schema;
    this.converter = options.converter;
    this.collection = undefined;
  }

  state() {
    return {
      items: [],
      current: null,
      loading: false,
    };
  }

  getters() {
    return {
      list: (state) => state.items,
      get: (state, id) => state.items[id],
      current: (state) => state.current,
      loading: (state) => state.loading,
      subscribe: (state) => state.subscribe,
      schema: () => this.schema,
    };
  }

  actions() {
    return {
      get: (context, payload) => {
        const { path, id } = payload;
        context.commit('setLoading', true);
        this.collection = fb.db.collection(path);

        return this.collection
          .doc(id)
          .withConverter(this.converter)
          .get()
          .then((doc) => {
            context.commit('addItem', doc.data());
            context.commit('setLoading', false);
            return doc.data();
          });
      },
      find: (context, payload) => {
        const { path, subscribe, order } = payload;
        this.collection = fb.db.collection(path);
        context.commit('setLoading', true);
        const list = [];
        let ref = this.collection;

        if (order) {
          if (order.desc) {
            ref = ref.orderBy(order.field, 'desc');
          } else {
            ref = ref.orderBy(order.field);
          }
        }

        if (subscribe) {
          return ref.withConverter(this.converter).onSnapshot((snapshot) => {
            context.commit('setLoading', false);
            snapshot.docChanges().forEach((change) => {
              if (change.type === 'added') {
                context.commit('addItem', change.doc.data());
              }
              if (change.type === 'modified') {
                context.commit('addItem', change.doc.data());
              }
              if (change.type === 'removed') {
                context.commit('deleteItem', change.doc.id);
              }
            });
          });
        }

        return ref
          .withConverter(this.converter)
          .get()
          .then((res) => {
            res.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              context.commit('addItem', doc.data());
              list.push(doc.data());
            });
            context.commit('setLoading', false);
            return list;
          });
      },
      save: (context, payload) => {
        const { data } = payload;
        if (typeof data.id === 'undefined' || data.id === null) {
          context.dispatch('create', payload);
        } else {
          context.dispatch('update', payload);
        }
      },
      update: (context, payload) => {
        const { path, data } = payload;
        if (typeof data.id === 'undefined' || data.id === null) {
          throw new Error('Id is required for update method');
        }
        this.collection = fb.db.collection(path);
        context.commit('setLoading', true);
        // const validator = new Schema(this.schema);
        return this.collection
          .doc(data.id)
          .withConverter(this.converter)
          .set(data)
          .then(() => this.collection
            .doc(data.id)
            .withConverter(this.converter)
            .get()
            .then((doc) => {
              context.commit('addItem', doc.data());
              context.commit('setLoading', false);
              return doc.data();
            }))
          .catch((errors) => {
            console.log(errors);
          });
      },
      patch: (context, payload) => {
        const { path, id, data } = payload;
        context.commit('setLoading', true);
        if (typeof id === 'undefined' || id === null) {
          throw new Error('Id is required for patch method');
        }
        if (typeof path === 'undefined' || path === null) {
          throw new Error('Path is required for patch method');
        }
        if (typeof data === 'undefined' || data === null) {
          throw new Error('Data is required for patch method');
        }
        this.collection = fb.db.collection(path);

        return this.collection
          .doc(id)
          .withConverter(this.converter)
          .update(data)
          .then(() => this.collection
            .doc(id)
            .withConverter(this.converter)
            .get()
            .then((doc) => {
              context.commit('addItem', doc.data());
              context.commit('setLoading', false);
              return doc.data();
            }));
      },
      create: (context, payload) => {
        const { path, data } = payload;
        this.collection = fb.db.collection(path);
        context.commit('setLoading', true);
        const validator = new Schema(this.schema);
        return validator
          .validate(data)
          .then(() => this.collection
            .withConverter(this.converter)
            .add(data)
            .then((res) => {
              res.get().then((doc) => {
                context.commit('addItem', doc.data());
                context.commit('setLoading', false);
                return doc.data();
              });
            }))
          .catch((err) => {
            console.log(err);
            context.commit('setLoading', false);
          });
      },
      delete: (context, payload) => {
        const { path, id } = payload;
        if (typeof id === 'undefined' || id === null) {
          throw new Error('Id is required for delete method');
        }
        if (typeof path === 'undefined' || path === null) {
          throw new Error('Path is required for delete method');
        }
        this.collection = fb.db.collection(path);
        // context.commit('setLoading', true);
        return this.collection
          .doc(id)
          .delete()
          .then(() => {
            context.commit('deleteItem', id);
            context.commit('setLoading', false);
          })
          .catch((err) => {
            console.log(err);
            context.commit('setLoading', false);
          });
      },
      setCurrent: (context, payload) => context.commit('setCurrent', payload),
      clearItems: (context) => context.commit('clearItems'),
      unSubscribe: (context) => {
        if (context.state.subscribe) {
          context.state.subscribe();
        }
      },
    };
  }

  mutations() {
    return {
      setCurrent: (state, payload) => {
        state.current = payload;
      },
      setItems: (state, payload) => {
        state.items = payload;
      },
      addItem: (state, doc) => {
        let item = null;
        if (doc) {
          item = _.find(state.items, { id: doc.id });
        }

        if (item) {
          // state.items.splice(index, 1);
          Object.assign(item, doc);
        } else {
          state.items.push(doc);
        }
      },
      deleteItem: (state, id) => {
        const index = _.findIndex(state.items, { id });
        state.items.splice(index, 1);
      },
      setLoading: (state, payload) => {
        state.loading = payload;
      },
      clearItems: (state) => {
        state.items = [];
      },
    };
  }

  modules() {
    return {};
  }

  getModule = () => ({
    namespaced: true,
    state: this.state(),
    getters: this.getters(),
    actions: this.actions(),
    mutations: this.mutations(),
    modules: this.modules(),
  });
}
/* eslint-enable class-methods-use-this */
